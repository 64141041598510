.Projects {
    align-items: center;
    background-color: #00296b;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 60px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 100px;
    position: relative;
    width: 100%;
}

.Projects__container {
    align-items: center;
    background-color: #00296b;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 1000px;
    padding: 0 100px;
}

.Projects__title__container {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    max-width: 1000px;
    flex: none;
    height: auto;
    max-width: 1000px;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
}

.Projects__title {
   @apply text-[#FDC500] text-[32px] mb-6;
   outline: none;
   text-align: left;
   font-family: "Goblin One", serif;
}

.Projects__description__container_base{
    @apply flex items-center justify-center;
}

.Projects__description__container {
    @apply flex flex-row flex-wrap gap-4 items-center;
    gap: 20px;
    height: min-content;
    mix-blend-mode: luminosity;
    overflow: visible;
    padding: 0;
    width: 100%;
    max-width: 1000px;
}

.projects__project {
    align-self: start;
    border-radius: 40px;
    flex: none;
    justify-self: start;
    overflow: hidden;
    position: relative;
    height: 350px;
    width: 350px;
    cursor: pointer;
    position: relative;
    min-width: 200px;

}

.projects__projects > img {
    display: block;
    width: 500px;
    height: 490px;
    border-radius: inherit;
    object-position: center;
    object-fit: cover;
    image-rendering: auto;
}

.project-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* semi-transparent black */
    color: white;
    text-align: center;
    padding: 10px;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
}

.projects__project:hover .project-description {
    transform: translateY(0);
}

.project-description-text {
    font-family: "Montserrat", sans-serif;
}

.project-link {
    @apply font-bold text-[#white] text-[20px] mb-6;
}



@media (max-width: 809px){
    .Projects {
        padding: 60px 20px;
    }

    .Projects__container {
        padding: 60px 20px;
    }

    .Projects__title {
        font-size: 30px;
    }

} 

@media ((max-width: 374px)){

    .projects__project {
        flex-basis: content;
        height: 310px;
        width : 310px;
        border-radius: 40px;

     }
     .projects__project > img {
         height: 310px;
         width : 310px;
         border-radius: 40px;
 
     }
     .project-description-text {
        font-size: 13px;
     }

 

}

