.BurningQuestions {
    align-items: center;
    background-color: #00296b;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 60px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 100px;
    position: relative;
    width: 100%;
}

.BurningQuestions__container {
    align-items: center;
    background-color: #00296b;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 1000px;
    padding: 0 100px;
}

.BurningQuestions__title__container {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    max-width: 1000px;
    flex: none;
    height: auto;
    max-width: 1000px;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
}

.BurningQuestions__title {
    @apply text-[#FDC500] text-[32px] mb-6;
    outline: none;
    text-align: left;
    font-family: "Goblin One", serif;
}

.BurningQuestions__description__container_base {
    /* align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 100px;
    height: min-content;
    justify-content: center;
    max-width: 1000px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    padding-top: 20px; */
    display:  flex;
    flex-direction:  column;
    gap:  20px;
}

.BurningQuestions__description__container {
    /* align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    max-width: 100%;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1px;
    gap: 16px;
    background: red; */

    display: flex;
    flex-direction: row;
    gap: 100px;
}

.BurningQuestions__group_question {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
    gap: 10px;
}

.BurningQuestions__question {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    height: auto;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    display: flex;
    word-break: break-word;
    word-wrap: break-word;
    font-family: "Atkinson Hyperlegible";
    font-size: 20px;
    letter-spacing: -0.025em;
    line-height: 1.5em;
    text-align: left;
    color: rgb(253, 197, 0);
}

.BurningQuestions_answer {
    height: auto;
    max-width: 400px;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    display: flex;
    font-family: "Atkinson Hyperlegible";
    line-height: 1.5em;
    text-align: left;
    font-size: 14px;
    color: rgb(253, 197, 0);
}

@media (max-width: 809px){
    .BurningQuestions {
        padding: 10px;
    }

    .BurningQuestions__title {
        font-size: 32px;
    }

    .BurningQuestions__container {
        padding: 0 50px;
    }

    .BurningQuestions__description__container {
        flex-direction: column;
        gap: 20px;
    }

    .BurningQuestions__question {
        font-size: 16px;
    }

    .BurningQuestions_answer {
        font-size: 13px;
    }

    .BurningQuestions__group_question {
        gap: 10px;
    }
  }