.Footer {
    align-items: center;
    background-color: #00296b;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 80px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 100px;
    position: relative;
    width: 100%;
    /* overflow-x: hidden; */
}

.Footer__container {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
    z-index: 2;
}

.Footer__title {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    transform: none;
    height: auto;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
    outline: none;
    text-align: center;
    font-family: "Goblin One", serif;
    font-size: 32px;
    color: #fdc500;
}

.Footer__title__container {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    max-width: 1000px;
    flex: none;
    height: auto;
    max-width: 1000px;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
}


.Footer__description {
    flex: none;
    height: auto;
    max-width: 100%;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 500px;
    word-break: break-word;
    word-wrap: break-word;
    font-family: "Atkinson Hyperlegible", "Atkinson Hyperlegible Placeholder", sans-serif;
    line-height: 1.5em;
    text-align: center;
    color: rgb(253, 197, 0);
}

.Footer__description__container {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    max-width: 1000px;
    flex: none;
    height: auto;
    max-width: 1000px;
    overflow: visible;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
}

.Footer__container_copyrights {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
}

.Footer__container_socials {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
}

.Footer__socials {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    height: min-content;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
}

.Footer__container_credits {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    height: min-content;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
}

.Footer__credits {
    outline: none;
    text-align: left;
    font-family: "Inter";
    font-size: 13px;
    color: rgb(255, 255, 255);
    line-height: 1.2em;
    margin-top: -10px;
}

.Footer_button_container {
    @apply flex container justify-center rounded-lg gap-2 mt-6;
}

.Footer_button_container button {
    align-items: center;
    @apply w-28 h-10 rounded-xl bg-[#fdc500] text-black;
    font-family: "Atkinson Hyperlegible";
    font-size: 14px;
}

.Footer__socials {
    display: flex;
    flex-direction: row;
}

.Footer__socials > div > svg {
    /* background: white; */
    height: 40px;
    width: 40px;
}


.Footer_decoration_left {
    --border-bottom-width: 1px;
    --border-color: #fdc500;
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    border-radius: 100%;
    flex: none;
    height: 252px;
    left: 12px;
    opacity: .3;
    overflow: hidden;
    position: absolute;
    top: 44px;
    transform: rotate(-124deg);
    width: 479px;
    /* will-change: var(--framer-will-change-override, transform); */
    z-index: 0;
    overflow: hidden;
}

.Footer_decoration_left::after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none;
}

.Footer_decoration_right {
    --border-bottom-width: 1px;
    --border-color: #fdc500;
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    border-radius: 100%;
    flex: none;
    height: 252px;
    left: 12px;
    opacity: .3;
    overflow: hidden;
    position: absolute;
    top: 44px;
    transform: rotate(-26deg);
    width: 479px;
    will-change: var(--framer-will-change-override, transform);
    z-index: 0;
    overflow: hidden;

}

.Footer_decoration_right::after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none;
}

.Footer_socials_linkedin_container {
    cursor: pointer;
}

@media (max-width: 809px) {
    .Footer {
        padding: 60px 20px;
    }

    .Footer__container {
        padding: 60px 20px;
    }

    .Footer__title {
        font-size: 30px;
    }

    .Footer__description {
        font-size: 14px;
    }

    .Footer_decoration_left {
        height: 199px;
        left: 63px;
        order: 1;
        top: 70px;
        width: 378px;
        overflow: hidden;

    }

    .Footer_decoration_right {
        height: 199px;
        left: 63px;
        order: 0;
        top: 70px;
        width: 378px;
        overflow: hidden;
    }
}


@media (max-width: 460px) {
    .Footer_decoration_left {
        height: 199px;
        left: 0px;
        order: 1;
        top: 70px;
        width: 378px;
        overflow: hidden;

    }
    .Footer_decoration_right {
        height: 199px;
        left: 0px;
        order: 0;
        top: 70px;
        width: 378px;
        overflow: hidden;
    }

}
