.BannerNumbers{
    @apply flex justify-center flex-col items-center;
}

.BannerNumbers__container {
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;
    height: min-content;
    max-width: 1000px;
    overflow: hidden;
    padding: 0;
    width: 100%;
    z-index: 1;

    align-items: flex-start;
    background-color: #fff;
    display: flex;
    flex: none;
    flex-direction: flex-start;
    flex-wrap: nowrap;
    gap: 40px;
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    padding: 100px;
    position: relative;
    width: 100%;
}

.BannerNumbers__title {
    font-family: 'Goblin One', serif;
    @apply text-[47px];
}

.BannerNumbers__description {
    @apply text-[22px] text-[#003f88] text-left;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

.BannerNumbers__numbers__container {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;
    height: min-content;
    justify-content: center;
    max-width: 600px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
}

.BannerNumbers_stats__container {
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    max-width: 1000px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
}

.BannerNumbers_stats__stat__number {
    font-family: "Goblin One", sans-serif;
    @apply text-[32px] w-[150px] text-left;
}

@media (max-width: 809px){
    .BannerNumbers_stats__container{
        flex-direction: column;
    }

    .BannerNumbers__container{
        padding: 60px 20px;
    }

    .BannerNumbers__description {
        @apply text-[20px];
    }

    .BannerNumbers__title {
        @apply text-[30px];
    }
}


.BannerNumbers_stats__stat__description {
    font-size: "Atkinson Hyperlegible";
    @apply text-[#003f88] text-left;
}