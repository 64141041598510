.BannerTitle {
  box-sizing: border-box;
  place-content: center;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column;
  gap: 50px;
  height: min-content;
  min-height: 800px;
  overflow: visible;
  padding: 100px;
  position: relative;
  width: 100%;
  background-image: url("/public/salim-42.jpeg");
  background-size: cover;
  background-position: center top;

}

.BannerTitle__title > * {
  font-family: "Goblin One";
  font-size: 77px;
  letter-spacing: em;
  box-sizing: border-box;
  border-collapse: separate;
  border-spacing: 0px 0px;
  caption-side: top;
  color: white;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  letter-spacing: normal;
  list-style: none;
  orphans: 2;
  quotes: auto;
  speak: normal;
  text-align: center;
  text-indent: 0;
  text-transform: uppercase;
  visibility: visible;
  white-space: pre;
  widows: 2;
  word-spacing: 0px
}

.BannerTitle__buttons__container{
  @apply flex flex-row container justify-center rounded-lg gap-2;
}

.BannerTitle__buttons__button1{
  align-items: center;
  @apply w-32 h-11 ;
  font-size: "Atkinson Hyperlegible";

  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.7rem 2rem; */
  font-weight: 700;
  /* font-size: 10px; */
  text-align: center;
  text-decoration: none;
  color: #fff;
  backface-visibility: hidden;
  border: 0.3rem solid transparent;
  border-radius: 3rem;

  border-color: transparent;
  background-color: #003f88;
  color: white;
  transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);

}

.BannerTitle__buttons__button1:hover {
  transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

.BannerTitle__buttons__button2{
  align-items: center;
  @apply w-32 h-11 ;
  font-size: "Atkinson Hyperlegible";

  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.7rem 2rem; */
  font-weight: 700;
  /* font-size: 10px; */
  text-align: center;
  text-decoration: none;
  color: #fff;
  backface-visibility: hidden;
  border: 0.3rem solid transparent;
  border-radius: 3rem;

  border-color: transparent;
  background-color: white;
  color: #003f88;
  transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);

}

.BannerTitle__buttons__button2:hover {
  transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

@media((min-width: 810px) and (max-width: 1280px)){
  .BannerTitle__title > * {
    @apply text-[46px];
  }
}

@media ((min-width: 560px) and  (max-width: 809px)){
  .BannerTitle__title > * {
    @apply text-[36px];
  }
}

@media (max-width: 559px){
  .BannerTitle__title > * {
    @apply text-[26px];
  }

  .BannerTitle__buttons__container{
    @apply flex flex-col items-center justify-center;
  }

  .BannerTitle__buttons__button1{
    @apply w-36 h-10;
  }
  .BannerTitle__buttons__button2{
    @apply w-36 h-10;
  }
}

@media (max-width: 390px){
  .BannerTitle__title > * {
    @apply text-[20px];
  }
}